import { PublicClientApplication } from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZ_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: process.env.REACT_APP_UI_BASE_URL
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)

const tokenRequest = {
  account: msalInstance.getAllAccounts()[0],
  scopes: [`${process.env.REACT_APP_AZ_CLIENT_ID}/.default`]
}

export const getToken = () => {
  msalInstance
    .acquireTokenSilent(tokenRequest)
    .then((response) => localStorage.setItem('token', response.accessToken))
    .catch((err) => {
      if (err.name === 'InteractionRequiredAuthError') {
        return msalInstance
          .acquireTokenRedirect(tokenRequest)
          .then((response) =>
            localStorage.setItem('token', response.accessToken)
          )
      }
    })
}
