import React from 'react'
import '../styling/components/PageHeader.css'

const PageHeader = ({ text, actions }) => {
  return (
    <div className='pageheader'>
      <h2 className='pageheader-title'>{text}</h2>
      <div className='pageheader-buttons'>{actions}</div>
    </div>
  )
}

export default PageHeader
