import LabelStudio from 'label-studio'

export const initializeLabelStudio = (
  userId,
  labelConfig,
  annotations,
  url,
  setFunc,
  setLoading
) => {
  const getLabels = (type, labels) => {
    switch (type) {
      case 'OD':
        return `
          <RectangleLabels name="tag" toName="img" canRotate="false">
            ${labels.map((label) => `<Label value="${label}"></Label>`)}
          </RectangleLabels>
        `
      case 'CL':
        return `
          <Choices name="choice" toName="img" showInLine="true">
            ${labels.map((label) => `<Choice value="${label}"/>`)}
          </Choices>
        `
      case 'MCL':
        return `
          <Choices name="choice" toName="img" showInLine="true" choice="multiple">
            ${labels.map((label) => `<Choice value="${label}"/>`)}
          </Choices>
        `
      case 'SEG':
        return `
          <PolygonLabels name="label" toName="img" strokeWidth="3" pointSize="small" opacity="0.9">
            ${labels.map((label) => `<Label value="${label}"></Label>`)}
          </PolygonLabels>
        `
      case 'KP':
        return `
          <KeyPointLabels name="label" toName="img" strokewidth="2" opacity="1" >
              ${labels.map((label) => `<Label value="${label}"/>`)}
          </KeyPointLabels>
        `
      default:
        return `
          <Choices name="choice" toName="img" showInLine="true">
            ${labels.map((label) => `<Choice value="${label}"/>`)}
          </Choices>
        `
    }
  }

  const save = (annotation) => {
    setLoading(true)
    setFunc(annotation.serializeAnnotation())
    setLoading(false)
  }

  const labelStudioConfig = {
    config: `
    <View>
      ${Object.keys(labelConfig).map((key) => getLabels(key, labelConfig[key]))}
      <Image crosshair="true" name="img" value="$image" zoomControl="true"></Image>
    </View>`,
    interfaces: ['panel', 'update', 'controls', 'side-column'],
    user: {
      pk: 1,
      firstname: userId
    },
    task: {
      annotations: annotations,
      predictions: [],
      id: 1,
      data: {
        image: url
      }
    },
    onLabelStudioLoad: function (LS) {
      var c = LS.annotationStore.addAnnotation({
        userGenerate: true
      })
      LS.annotationStore.selectAnnotation(c.id)
    },
    onSubmitAnnotation: async function (LS, annotation) {
      save(annotation)
    },
    onUpdateAnnotation: async function (LS, annotation) {
      save(annotation)
    }
  }
  new LabelStudio('label-studio', labelStudioConfig)
}
