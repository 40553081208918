import React, { useEffect, useState } from 'react'
import './App.css'
import Header from './components/Header'
import Nav from './components/Nav'
import Spinner from './components/Spinner'
import Alerter from './components/Alerter'
import { Routes, Route } from 'react-router-dom'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Dashboard from './pages/Dashboard'
import Boxes from './pages/Boxes'
import Box from './pages/Box'
import UC from './pages/UC'
import Usecases from './pages/Usecases'
import ErrorPage from './pages/ErrorPage'
import UCInfo from './pages/UCInfo'
import Label from './pages/Label'
import UCOutput from './pages/UCOutput'
import { getToken } from './authConfig'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { AlertContext } from './contexts/AlertContext'
import { UCContext } from './contexts/UCContext'

const App = () => {
  const adminValue = 'CapticAdmin'
  const userValue = 'CapticUser'

  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const [knownUser, setKnownUser] = useState(false)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()
  const [alert, setAlert] = useState({
    message: '',
    severity: 'error'
  })
  const [currentCreateUC, setCurrentCreateUC] = useState({
    module: 'other'
  })

  useEffect(() => {
    if (isAuthenticated) {
      const roles = accounts[0].idTokenClaims.roles
        ? accounts[0].idTokenClaims.roles
        : []
      setKnownUser(adminValue === roles[0] || userValue === roles[0])
      setUser({
        email: accounts[0].username,
        role: roles ? roles[0] : null
      })
      setLoading(false)
    }
  }, [accounts, isAuthenticated])

  if (!isAuthenticated) {
    instance.loginRedirect().catch((e) => console.log(e))
  } else {
    getToken()
    return (
      <div className='app'>
        <AlertContext.Provider value={{ alert, setAlert }}>
          <UCContext.Provider value={{ currentCreateUC, setCurrentCreateUC }}>
            {/* <Router> */}
            <HistoryRouter history={createBrowserHistory({ window })}>
              <Header displayAvatar={knownUser} />
              {loading ? (
                <Spinner />
              ) : knownUser ? (
                <div className='app-body'>
                  <Nav
                    className='app-body-left'
                    admin={user.role === adminValue}
                  />
                  <div className='app-body-right'>
                    <Routes>
                      <Route path='/' element={<Dashboard />} />
                      <Route path='/boxes' element={<Boxes />} />
                      <Route path='/boxes/create' element={<Box />} />
                      <Route path='/boxes/:boxId' element={<Box />} />
                      <Route path='/use-cases' element={<Usecases />} />
                      <Route path='/use-cases/create' element={<UC />}>
                        <Route
                          path='/use-cases/create/info'
                          element={<UCInfo />}
                        />
                        <Route
                          path='/use-cases/create/examples'
                          element={<Label />}
                        />
                        <Route
                          path='/use-cases/create/output'
                          element={<UCOutput />}
                        />
                      </Route>
                      <Route path='/use-cases/:ucId' element={<UC />}>
                        <Route
                          path='/use-cases/:ucId/info'
                          element={<UCInfo />}
                        />
                        <Route
                          path='/use-cases/:ucId/examples'
                          element={<Label />}
                        />
                        <Route
                          path='/use-cases/:ucId/output'
                          element={<UCOutput />}
                        />
                      </Route>
                      {/* {user.role === adminValue && (
                  <Route path='/users' element={<Users />} />
                )} */}
                      <Route path='/*' element={<ErrorPage />} />
                    </Routes>
                  </div>
                </div>
              ) : (
                <div className='app-unknown'>
                  <h2>Hi there, we haven't met yet! 👋</h2>
                  <p>
                    If your company has a Captic subscription, your AD admin
                    will have to grant you access to our platform.
                  </p>
                  <p>
                    If you don't have a subscription yet, or you have a
                    question, please contact us at{' '}
                    <a href='mailto:info@captic.com'>info@captic.com</a>!
                  </p>
                </div>
              )}
              {/* </Router> */}
            </HistoryRouter>
            <Alerter />
          </UCContext.Provider>
        </AlertContext.Provider>
      </div>
    )
  }
}

export default App
