import React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Link, useLocation } from 'react-router-dom'
import '../styling/components/NavLink.css'

const NavLink = ({ path, text }) => {
  const location = useLocation()

  console.log(location.pathname)

  let active = false
  if (
    (path === '/' && location.pathname === path) ||
    (path !== '/' && location.pathname.includes(path))
  ) {
    active = true
  }

  return (
    <Link className='navlink' to={path} data-testid='navlink-link'>
      <ListItemButton>
        <ListItemText
          className={'navlink-text ' + (active ? 'navlink-text-active' : '')}
          data-testid='navlink-text'
        >
          {text}
        </ListItemText>
      </ListItemButton>
    </Link>
  )
}

export default NavLink
