import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import '../styling/components/Table.css'

const Table = ({ columns, rows }) => {
  return (
    <DataGrid
      autoHeight
      pageSize={5}
      disableSelectionOnClick
      className='table'
      rows={rows}
      columns={columns}
    />
  )
}

export default Table
