import axiosClient from '../config'

export const getUCs = () => {
  return axiosClient.get('/use-cases').then((result) => result.data)
}

export const getUC = (id) => {
  return axiosClient.get('/use-cases/' + id).then((result) => result.data)
}

export const addUC = (uc) => {
  let examples = {}
  Object.keys(uc.examples).forEach(
    (key) => (examples[key.split('?')[0]] = uc.examples[key])
  )

  return axiosClient.post('/use-cases', {
    name: uc.name,
    box_id: uc.box_id,
    description: uc.description || '',
    module_id: uc.module,
    label_config: uc.label_config,
    examples: examples
  })
}

export const updateUC = (uc) => {
  let examples = {}
  Object.keys(uc.examples).forEach(
    (key) => (examples[key.split('?')[0]] = uc.examples[key])
  )

  return axiosClient.patch('/use-cases', {
    id: uc.id,
    name: uc.name,
    description: uc.description,
    examples: examples
  })
}

export const deleteUC = (id) => {
  return axiosClient.delete('/use-cases/' + id)
}
