import React, { useState, useEffect, useContext } from 'react'
import '../styling/pages/Boxes.css'
import ActionButton from '../components/ActionButton'
import PageHeader from '../components/PageHeader'
import Table from '../components/Table'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Chip from '@mui/material/Chip'
import Spinner from '../components/Spinner'
import RefreshIcon from '@mui/icons-material/Refresh'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
import { getBoxes } from '../api/lib/boxes.js'
import { AlertContext } from '../contexts/AlertContext'

const Boxes = () => {
  const [loading, setLoading] = useState(true)

  const [columns] = useState([
    {
      field: 'id',
      headerName: 'Id',
      resizable: false,
      minWidth: 100,
      flex: 1
    },
    {
      field: 'plant',
      headerName: 'Plant',
      resizable: false,
      minWidth: 100,
      flex: 1
    },
    {
      field: 'line',
      headerName: 'Line',
      resizable: false,
      minWidth: 100,
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      resizable: false,
      minWidth: 100,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      resizable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            className={
              'table-status' + (params.value === 'connected' ? '-green' : '')
            }
            label={params.value}
            variant='outlined'
          />
        )
      }
    },
    {
      headerName: '',
      resizable: false,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Link to={'/boxes/' + params.row.id}>
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </Link>
        )
      }
    }
  ])

  const [boxes, setBoxes] = useState([])

  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    setLoading(true)
    getBoxes()
      .then((result) => {
        setBoxes(result)
        setLoading(false)
      })
      .catch((error) =>
        setAlert({
          message: error.response.data.message,
          severity: 'error'
        })
      )
  }, [setAlert])

  return (
    <div className='boxes'>
      <PageHeader
        text='Boxes'
        actions={[
          <ActionButton
            key='refresh-button'
            icon={<RefreshIcon />}
            onClick={() => window.location.reload()}
          />,
          <Link key='create' to='/boxes/create'>
            <ActionButton
              key='add-box-button'
              icon={<AddCircleOutlineIcon />}
            />
          </Link>
        ]}
      />
      {loading && boxes && columns ? ( // eslint-disable-line no-eval
        <Spinner />
      ) : (
        <Table columns={columns} rows={boxes} />
      )}
    </div>
  )
}

export default Boxes
