import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import ActionButton from '../components/ActionButton'
import PageHeader from '../components/PageHeader'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Table from '../components/Table'
import Chip from '@mui/material/Chip'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import Spinner from '../components/Spinner'
import { getBoxes } from '../api/lib/boxes'
import { getUCs } from '../api/lib/useCases.js'
import { AlertContext } from '../contexts/AlertContext'
import { UCContext } from '../contexts/UCContext'

const Usecases = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [ucs, setUCs] = useState([])

  const [boxIdOptions, setBoxIdOptions] = useState([])

  const [columns] = useState([
    {
      field: 'name',
      headerName: 'name',
      resizable: false,
      minWidth: 100,
      flex: 1
    },
    {
      field: 'box_id',
      headerName: 'Box Id',
      resizable: false,
      minWidth: 100,
      flex: 1
    },
    {
      field: 'plant',
      headerName: 'Plant',
      resizable: false,
      flex: 1
    },
    {
      field: 'line',
      headerName: 'Line',
      resizable: false,
      minWidth: 100,
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      resizable: false,
      minWidth: 100,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      resizable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            className={
              'table-status' + (params.value === 'active' ? '-green' : '')
            }
            label={params.value}
            variant='outlined'
          />
        )
      }
    },
    {
      headerName: '',
      resizable: false,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Link to={`/use-cases/${params.row.id}/info`}>
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </Link>
        )
      }
    }
  ])

  const { setAlert } = useContext(AlertContext)
  const { setCurrentCreateUC } = useContext(UCContext)

  useEffect(() => {
    setLoading(true)
    getBoxes().then((boxes) => setBoxIdOptions(boxes.map((item) => item.id)))
    getUCs().then((result) => {
      setUCs(result)
      setLoading(false)
    })
  }, [])

  const handleCreate = () => {
    if (boxIdOptions.length > 0) {
      setCurrentCreateUC({
        module: 'other'
      })
      navigate('/use-cases/create/info')
    } else {
      setAlert({
        message: 'Use cases can only be created once boxes are added.',
        severity: 'error'
      })
    }
  }

  return (
    <div className='ucs'>
      <PageHeader
        text='Use Cases'
        actions={[
          <ActionButton
            key='refresh-button'
            icon={<RefreshIcon />}
            onClick={() => window.location.reload()}
          />,
          <ActionButton
            key='add-button'
            onClick={handleCreate}
            icon={<AddCircleOutlineIcon />}
          />
        ]}
      />
      {loading && ucs && columns ? ( // eslint-disable-line no-eval
        <Spinner />
      ) : (
        <Table columns={columns} rows={ucs} />
      )}
    </div>
  )
}

export default Usecases
