import React from 'react'
import ActionButton from '../components/ActionButton'
import PageHeader from '../components/PageHeader'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Link } from 'react-router-dom'

const Dashboard = () => {
  return (
    <div className='dashboard'>
      <PageHeader
        text='Dashboard'
        actions={[
          // <ActionButton
          //   key='add-user-button'
          //   text='Add a new user'
          //   icon={<AddCircleOutlineIcon />}
          //   onClick={() => navigate('/users?create=true')}
          // />,
          <Link to={'/boxes/create'}>
            <ActionButton
              key='add-box-button'
              icon={<AddCircleOutlineIcon />}
            />
          </Link>
        ]}
      />
    </div>
  )
}

export default Dashboard
