import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useMsal } from '@azure/msal-react'
import '../styling/components/Form.css'

const Dropdown = ({ question, label, creatable, options, setFunc, value }) => {
  const { accounts } = useMsal()
  const adminValue = 'CapticAdmin'

  return (
    <div className='form'>
      <p className='form-title'>{question}</p>
      <Autocomplete
        // key={value}
        defaultValue={options[0]}
        value={value}
        onInputChange={(e) => {
          if (
            creatable &&
            accounts[0].idTokenClaims.roles[0] === adminValue &&
            e
          ) {
            let val =
              typeof e.target.value === 'number'
                ? options[e.target.value]
                : e.target.value
            setFunc(val)
          }
        }}
        onChange={(e, val) => {
          setFunc(val)
        }}
        options={options}
        freeSolo={
          creatable && accounts[0].idTokenClaims.roles[0] === adminValue
        }
        disableClearable
        renderInput={(params) => <TextField {...params} label={label} />}
        shrink={true}
      />
    </div>
  )
}

export default Dropdown
