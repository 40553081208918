import React, { useEffect, useState, useContext } from 'react'
import Crud from '../components/Crud'
import Dropdown from '../components/Dropdown'
import TextInput from '../components/TextInput'
import ActionButton from '../components/ActionButton'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { deleteUC, updateUC, getUC } from '../api/lib/useCases.js'
import { getBoxes } from '../api/lib/boxes'
import { UCContext } from '../contexts/UCContext'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import '../styling/pages/UC.css'

const UC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const [create, setCreate] = useState(true)
  const [uc, setUC] = useState({})
  const [loading, setLoading] = useState(false)
  const [moduleOptions, setModuleOptions] = useState([])
  const [groupedModuleOptions, setGroupedModuleOptions] = useState()
  const [boxIdOptions, setBoxIdOptions] = useState([])

  const { currentCreateUC, setCurrentCreateUC } = useContext(UCContext)

  useEffect(() => {
    setCreate(location.pathname.includes('/use-cases/create/'))
  }, [location.pathname])

  useEffect(() => {
    if (create) {
      setGroupedModuleOptions(
        moduleOptions.reduce(
          (groups, item) => ({
            ...groups,
            [item.category]: [...(groups[item.category] || []), item]
          }),
          {}
        )
      )
    }
  }, [moduleOptions, create])

  useEffect(() => {
    if (create) {
      getBoxes().then((boxes) => setBoxIdOptions(boxes.map((item) => item.id)))
      setModuleOptions([
        {
          id: '46aa85ee-6732-4b42-8d92-0bc4fed0f2b1',
          name: 'Counting: cookies',
          category: 'counting',
          label_config: {
            OD: ['cookie']
          }
        },
        {
          id: '46aa85ee-6732-4b42-8d92-0bc4fed0f2b2',
          name: 'Counting: chickens',
          category: 'counting',
          label_config: {
            OD: ['chicken']
          }
        },
        {
          id: '46aad5ee-6732-4b42-8d92-0bc4fed0f2b2',
          name: 'Counting: carrots',
          category: 'counting',
          label_config: {
            OD: ['carrot']
          }
        },
        {
          id: '46aa85ee-6732-4b42-8d92-0bc4fed0f2b3',
          name: 'Detect: cookies',
          category: 'detect',
          label_config: {
            OD: ['cookie']
          }
        },
        {
          id: '46aa85ee-6732-4b42-8d92-0bc4fed0f2b4',
          name: 'Detect: datura',
          category: 'detect',
          label_config: {
            OD: ['datura']
          }
        },
        {
          id: '46aa85ee-6732-4b42-8d92-0bc4fed0f2b5',
          name: 'QC: category grading',
          category: 'QC',
          label_config: {
            CL: ['A', 'B']
          }
        },
        {
          id: '46aa85ee-6732-4b42-8d92-0bc4fed0f2ds',
          name: 'Area: calculate from squares',
          category: 'area',
          label_config: {
            OD: ['A']
          }
        },
        {
          id: '46aa85ee-6732-4b42-8d92-0bc4fed0f2d6',
          name: 'Area: free shape',
          category: 'area',
          label_config: {
            SEG: ['A']
          }
        }
      ])
    } else {
      setLoading(true)
      getUC(params.ucId)
        .then((result) => {
          let types =
            result.label_config[Object.keys(result.label_config)[0]].join(', ')
          setUC({ ...result, types: types })
          setCurrentCreateUC({
            ...result,
            types: types
          })
          setLoading(false)
        })
        .catch(() => {
          navigate('error')
        })
    }
  }, [create])

  const handleNext = () => {
    if (currentCreateUC.module === 'other') {
      console.log('HANDLE THIS')
    } else {
      navigate('/use-cases/create/examples')
    }
  }

  const checkComplete = () => {
    if (
      currentCreateUC &&
      currentCreateUC.name &&
      currentCreateUC.box_id &&
      currentCreateUC.module
    ) {
      if (
        currentCreateUC.module !== 'other' &&
        currentCreateUC.labels &&
        currentCreateUC.labels.length === 0
      ) {
        return true
      }
      return false
    }
    return true
  }

  return (
    <Crud
      create={create}
      loading={loading}
      setLoading={setLoading}
      name='use case'
      baseUrl='/use-cases'
      object={uc}
      editObject={currentCreateUC}
      setEditObject={setCurrentCreateUC}
      deleteFunc={deleteUC}
      updateFunc={updateUC}
      addButtonOverwrite={
        create && (
          <ActionButton
            key='next'
            icon={<NavigateNextIcon />}
            onClick={handleNext}
            disabled={checkComplete()}
          />
        )
      }
      infoTableItems={[
        'id',
        'status',
        'box_id',
        'owner_email',
        'module',
        'types'
      ]}
      createOnlyItems={[
        <Dropdown
          question='Select the Box on which to run the use case:'
          label='Box ID'
          options={boxIdOptions}
          setFunc={(val) =>
            setCurrentCreateUC({ ...currentCreateUC, box_id: val })
          }
          value={currentCreateUC?.box_id}
        />,
        <div key='form-item-1' className='form-item'>
          <p className='form-title'>Select the module for the use case:</p>
          <Select
            native
            className='box-card-select'
            value={currentCreateUC?.module}
            onChange={(e) => {
              setCurrentCreateUC({
                ...currentCreateUC,
                module: e.target.value,
                label_config: moduleOptions.find(
                  (x) => x.id === e.target.value
                )['label_config'],
                examples: {}
              })
            }}
          >
            <option value='other'>Other</option>
            {groupedModuleOptions &&
              Object.keys(groupedModuleOptions).map((key) => (
                <optgroup label={key}>
                  {groupedModuleOptions[key].map((opt) => (
                    <option value={opt.id}>{opt.name}</option>
                  ))}
                </optgroup>
              ))}
          </Select>
        </div>
      ]}
      formItems={[
        <TextInput
          title='Enter a name for the use case:'
          label='Name'
          value={currentCreateUC.name}
          setValue={(e) =>
            setCurrentCreateUC({ ...currentCreateUC, name: e.target.value })
          }
        />,
        <TextInput
          title='Description:'
          label='Description'
          big={true}
          value={currentCreateUC?.description}
          setValue={(e) =>
            setCurrentCreateUC({
              ...currentCreateUC,
              description: e.target.value
            })
          }
        />
      ]}
      extraFormItems={
        create && currentCreateUC && currentCreateUC.module !== 'other'
          ? [
              <div key='form-item-2' className='form-item'>
                <p className='form-title'>Define the types you want to use:</p>
                <Autocomplete
                  key={currentCreateUC.label_config[
                    Object.keys(currentCreateUC.label_config)[0]
                  ].join('-')}
                  multiple
                  options={[]}
                  defaultValue={
                    currentCreateUC.label_config[
                      Object.keys(currentCreateUC.label_config)[0]
                    ]
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder='Add types' />
                  )}
                  freeSolo
                  onChange={(e, value) => {
                    setCurrentCreateUC({
                      ...currentCreateUC,
                      label_config: {
                        [Object.keys(currentCreateUC.label_config)[0]]: value
                      }
                    })
                  }}
                />
              </div>
            ]
          : []
      }
      checkComplete={checkComplete}
    />
  )
}

export default UC
