import axios from 'axios'

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000
  })

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

axiosClient.interceptors.response.use(
  (response) => {
      return response
  },
  (error) => {
      if (error.response.status > 400) {
          window.location = '/error'
      } else {
          return Promise.reject(error)
      }
  }
)

export default axiosClient
