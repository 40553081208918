import React from 'react'
import NavLink from './NavLink'
import List from '@mui/material/List'
import LogoutIcon from '@mui/icons-material/Logout'
import { useMsal } from '@azure/msal-react'
import '../styling/components/Nav.css'

const Nav = ({ admin }) => {
  const { instance } = useMsal()

  const logout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/'
    })
  }

  return (
    <List className='nav'>
      <div className='nav-menu'>
        <NavLink path='/' text='Dashboard' />
        <NavLink path='/boxes' text='Boxes' />
        <NavLink path='/use-cases' text='Use Cases' />
        {/* {admin && <NavLink path='/users' text='Users' />} */}
      </div>
      <div className='nav-footer' onClick={logout}>
        <LogoutIcon fontSize='small' />
        <p>Log out</p>
      </div>
    </List>
  )
}

export default Nav
