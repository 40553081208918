import React, { useEffect, useState, useContext } from 'react'
import { UCContext } from '../contexts/UCContext'
import { useLocation, Outlet, useNavigate } from 'react-router-dom'

import '../styling/pages/UC.css'

const UC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [create, setCreate] = useState(true)
  const [disable, setDisable] = useState(false)

  const { currentCreateUC, setCurrentCreateUC } = useContext(UCContext)

  useEffect(() => {
    setCreate(location.pathname.includes('/use-cases/create'))
    setDisable(create && checkComplete())
  }, [location])

  const navigateTo = (s) => {
    if (create) {
      navigate(`/use-cases/create/${s}`)
    } else {
      navigate(`${location.pathname.split('/').slice(0, -1).join('/')}/${s}`)
    }
  }

  // TODO: fix duplicate code
  const checkComplete = () => {
    if (
      currentCreateUC &&
      currentCreateUC.name &&
      currentCreateUC.box_id &&
      currentCreateUC.module
    ) {
      if (currentCreateUC.module === 'other') {
        return true
      } else if (
        currentCreateUC.module !== 'other' &&
        currentCreateUC.labels &&
        currentCreateUC.labels.length === 0
      ) {
        return true
      }
      return false
    }
    return true
  }

  return (
    <div className='uc'>
      <div className='uc-left'>
        <div
          className={
            'uc-left-step' +
            (location.pathname.includes('/info') ? ' uc-left-step-active' : '')
          }
          onClick={() => navigateTo('info')}
        >
          <span>Info</span>
        </div>
        <div
          className={
            'uc-left-step' +
            (location.pathname.includes('/examples')
              ? ' uc-left-step-active'
              : '') +
            (disable ? ' uc-left-step-disabled' : '')
          }
          onClick={() => disable || navigateTo('examples')}
        >
          <span>Examples</span>
        </div>
        <div
          className={
            'uc-left-step' +
            (location.pathname.includes('/output')
              ? ' uc-left-step-active'
              : '') +
            (disable ? ' uc-left-step-disabled' : '')
          }
          onClick={() => disable || navigateTo('output')}
        >
          <span>Output</span>
        </div>
      </div>
      <div className='uc-right'>
        <Outlet />
      </div>
    </div>
  )
}

export default UC
