import React, { useContext, useEffect, useState } from 'react'
import Fade from '@mui/material/Fade'
import Alert from '@mui/material/Alert'
import { AlertContext } from '../contexts/AlertContext'
import '../styling/components/Alerter.css'

const Alerter = () => {
  const [show, setShow] = useState(false)
  const { alert, setAlert } = useContext(AlertContext)

  useEffect(() => {
    if (alert.message) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
        setAlert({ message: '', severity: alert.severity })
      }, 5000)
    }
  }, [alert, setAlert])

  return (
    <div className='alerter'>
      <Fade in={show}>
        <Alert id='alert-error' severity={alert.severity}>
          {alert.message}
        </Alert>
      </Fade>
    </div>
  )
}

export default Alerter
