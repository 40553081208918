import React, { useEffect, useState, useContext } from 'react'
import PageHeader from '../components/PageHeader'
import Spinner from '../components/Spinner'
import { initializeLabelStudio } from '../labelStudio'
import { useMsal } from '@azure/msal-react'
import { UCContext } from '../contexts/UCContext'
import { getExamples } from '../api/lib/boxes.js'
import { getUC, updateUC } from '../api/lib/useCases.js'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import '../styling/pages/Label.css'

const Label = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const { accounts } = useMsal()

  const [create, setCreate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [currentImg, setCurrentImg] = useState({})

  const { currentCreateUC, setCurrentCreateUC } = useContext(UCContext)

  useEffect(() => {
    setCreate(location.pathname.includes('/use-cases/create/'))
  }, [location.pathname])

  useEffect(() => {
    setLoading(true)
    if (create) {
      if (currentCreateUC.images) {
        setImages(currentCreateUC.images)
      } else {
        getExamples(currentCreateUC.box_id).then((result) => {
          let imgs = result.map((img, index) => {
            return {
              id: index,
              url: img
            }
          })
          setImages(imgs)
          setCurrentCreateUC({ ...currentCreateUC, images: imgs })
        })
      }
    }
    setLoading(false)
  }, [currentCreateUC, setCurrentCreateUC, create])

  useEffect(() => {
    if (!create && params.ucId) {
      getUC(params.ucId)
        .then((result) => {
          setCurrentCreateUC(result)
          setImages(
            Object.keys(result['examples']).map((key, i) => {
              return { id: i, url: key }
            })
          )
          setLoading(false)
        })
        .catch(() => {
          navigate('error')
        })
    }
  }, [create, params.ucId])

  useEffect(() => {
    try {
      initializeLabelStudio(
        accounts[0].idTokenClaims.oid,
        currentCreateUC['label_config'],
        currentCreateUC['examples'][currentImg.url]
          ? [currentCreateUC['examples'][currentImg.url]]
          : [],
        currentImg.url,
        (annotations) => {
          setCurrentCreateUC({
            ...currentCreateUC,
            examples: {
              ...currentCreateUC.examples,
              [currentImg.url]: { result: annotations }
            }
          })
          if (!create) {
            updateUC({
              ...currentCreateUC,
              examples: {
                ...currentCreateUC.examples,
                [currentImg.url]: { result: annotations }
              }
            })
          }
        },
        setLoading
      )
    } catch {}
  }, [currentImg, images, currentCreateUC, accounts, setCurrentCreateUC])

  useEffect(() => {
    setCurrentImg(images[0])
  }, [images])

  return (
    <div className='label'>
      <PageHeader text='Give some examples' />
      {loading ? ( // eslint-disable-line no-eval
        <Spinner />
      ) : (
        <div className='label-body'>
          <div className='label-slider'>
            {currentImg &&
              images.map((img) => (
                <img
                  key={img.id}
                  className={
                    'label-thumbnail' +
                    (img.id === currentImg.id ? ' label-thumbnail-active' : '')
                  }
                  src={img.url}
                  alt='thumbnail'
                  onClick={() => {
                    // handleSave()
                    setCurrentImg(img)
                  }}
                />
              ))}
          </div>
          <div id='label-studio'></div>
        </div>
      )}
    </div>
  )
}

export default Label
