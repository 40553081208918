import React from 'react'
import logo from '../assets/logo.svg'
import Avatar from '@mui/material/Avatar'
import { Link } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import '../styling/components/Header.css'

const Header = ({ displayAvatar }) => {
  const { accounts } = useMsal()
  const name = accounts[0].name

  return (
    <div className='header'>
      <Link to='/'>
        <img className='header-logo' alt='Captic logo' src={logo} />
      </Link>
      {displayAvatar && <Avatar>{name.charAt(0).toUpperCase()}</Avatar>}
    </div>
  )
}

export default Header
