import React, { useContext } from 'react'
import Spinner from './Spinner'
import ActionButton from './ActionButton'
import PageHeader from './PageHeader'
import { AlertContext } from '../contexts/AlertContext'
import { useNavigate } from 'react-router-dom'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import '../styling/components/Crud.css'

const Crud = ({
  create,
  loading,
  setLoading,
  name,
  baseUrl,
  object,
  editObject,
  deleteFunc,
  createFunc,
  addButtonOverwrite,
  updateFunc,
  infoTableItems,
  underTableItems,
  createOnlyItems,
  formItems,
  extraFormItems,
  checkComplete
}) => {
  const navigate = useNavigate()
  const { setAlert } = useContext(AlertContext)

  const lowerName = name.toLowerCase()
  const firstCaseName =
    name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()

  const doFunc = (noConfirm, func, verb) => {
    if (
      noConfirm ||
      window.confirm(`Are you sure you want to ${verb} this ${lowerName}?`)
    ) {
      setLoading(true)
      func()
        .then(() => {
          setAlert({
            message: `${firstCaseName} ${verb}d successfully.`,
            severity: 'success'
          })
          navigate(baseUrl)
        })
        .catch((error) => {
          setLoading(false)
          setAlert({ message: error.response.data.message, severity: 'error' })
        })
    }
  }

  return (
    <div className='crud'>
      {loading ? ( // eslint-disable-line no-eval
        <Spinner />
      ) : (
        <>
          <PageHeader
            text={
              create
                ? `Create a new ${lowerName}`
                : `Details ${lowerName}: ` + object?.id
            }
            actions={[
              ...(!create
                ? [
                    <ActionButton
                      icon={<DeleteOutlineIcon />}
                      space
                      onClick={() =>
                        doFunc(false, () => deleteFunc(editObject.id), 'delete')
                      }
                    />
                  ]
                : []),
              <ActionButton
                key='cancel'
                icon={<CancelIcon />}
                onClick={() => navigate(baseUrl)}
                cancel
              />,
              (create && addButtonOverwrite) || (
                <ActionButton
                  key='persist'
                  icon={<SaveOutlinedIcon />}
                  onClick={
                    create
                      ? () =>
                          doFunc(true, () => createFunc(editObject), 'create')
                      : () =>
                          doFunc(false, () => updateFunc(editObject), 'update')
                  }
                  disabled={checkComplete()}
                />
              )
            ]}
          />
          <div className='crud-body'>
            {!create && (
              <div className='crud-body-left'>
                <div className='crud-card'>
                  <table>
                    {infoTableItems.map((item) => {
                      return (
                        <tr className='crud-infotable-row'>
                          <td className='crud-infotable-title'>
                            {item.charAt(0).toUpperCase() +
                              item.replace('_', ' ').slice(1).toLowerCase()}
                            :
                          </td>
                          <td>{object[item]}</td>
                        </tr>
                      )
                    })}
                  </table>
                </div>
                {underTableItems && (
                  <div className='crud-card'>{underTableItems}</div>
                )}
              </div>
            )}
            <div className='crud-card'>
              {create && createOnlyItems}
              {formItems}
            </div>
            {extraFormItems && extraFormItems.length > 0 && (
              <div className='crud-card'>{extraFormItems}</div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Crud
