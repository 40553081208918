import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ActionButton from '../components/ActionButton'
import PageHeader from '../components/PageHeader'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { AlertContext } from '../contexts/AlertContext'
import { UCContext } from '../contexts/UCContext'
import { addUC } from '../api/lib/useCases.js'
import '../styling/pages/UCOutput.css'

const UCOutput = () => {
  const baseUrl = '/use-cases'

  const location = useLocation()
  const navigate = useNavigate()

  const [create, setCreate] = useState(false)
  const [, setLoading] = useState(false)

  const { setAlert } = useContext(AlertContext)
  const { currentCreateUC } = useContext(UCContext)

  useEffect(() => {
    setCreate(location.pathname.includes('/use-cases/create/'))
  }, [location.pathname])

  const handleCreate = () => {
    setLoading(true)
    addUC(currentCreateUC)
      .then(() => {
        setAlert({
          message: `Use Case created successfully.`,
          severity: 'success'
        })
        navigate(baseUrl)
      })
      .catch((error) => {
        setLoading(false)
        setAlert({ message: error.response.data.message, severity: 'error' })
      })
  }

  return (
    <div className='ucoutput'>
      <PageHeader
        text='Define the output'
        actions={[
          create && (
            <ActionButton
              key='add-button'
              onClick={handleCreate}
              icon={<AddCircleOutlineIcon />}
            />
          )
        ]}
      />
      <div className='ucoutput-body'>
        <div key='form-item-2' className='form-item'>
          <p className='form-title'>What do you want to do with the output:</p>
          <FormControlLabel
            disabled
            checked
            control={<Checkbox defaultChecked />}
            label="display output on box's screen"
          />
        </div>
      </div>
    </div>
  )
}

export default UCOutput
