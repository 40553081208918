import React from 'react'
import { CircularProgress } from '@mui/material'
import '../styling/components/Spinner.css'

const Spinner = () => {
  return (
    <div className='spinner'>
      <CircularProgress />
    </div>
  )
}

export default Spinner
