import React from 'react'
import IconButton from '@mui/material/IconButton'
import '../styling/components/ActionButton.css'

const ActionButton = ({
  key,
  icon,
  onClick,
  space = false,
  disabled = false
}) => {
  return (
    <IconButton
      key={key}
      disabled={disabled}
      onClick={() => onClick()}
      className={'actionbutton' + (space ? ' actionbutton-space' : '')}
    >
      {icon}
    </IconButton>
  )
}

export default ActionButton
