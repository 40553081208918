import React from 'react'
import TextField from '@mui/material/TextField'
import '../styling/components/Form.css'

const TextInput = ({ title, label, big, value, setValue }) => {
  return (
    <div className='form'>
      <p className='form-title'>{title}</p>
      <TextField
        id='uc-description'
        label={label}
        multiline={big}
        maxRows={big ? 3 : 1}
        minRows={big ? 3 : 1}
        value={value}
        onChange={setValue}
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )
}

export default TextInput
