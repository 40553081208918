import React, { useEffect, useState, useContext } from 'react'
import Crud from '../components/Crud'
import Dropdown from '../components/Dropdown'
import TextInput from '../components/TextInput'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'
import {
  getBox,
  deleteBox,
  updateBox,
  addBox,
  getPlants,
  getLines
} from '../api/lib/boxes'
import { AlertContext } from '../contexts/AlertContext'
import '../styling/pages/Box.css'

const Box = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const [create, setCreate] = useState(true)
  const [box, setBox] = useState({})
  const [formBox, setFormBox] = useState({
    plant: 'other',
    line: 'other'
  })
  const [loading, setLoading] = useState(false)
  const [plantOptions, setPlantOptions] = useState(['other'])
  const [lineOptions, setLineOptions] = useState(['other'])

  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    setLoading(true)
    getPlants().then((plants) => setPlantOptions(plants))
    if (location.pathname !== '/boxes/create') {
      setCreate(false)
      let n = parseInt(params.boxId)
      if (n) {
        getBox(n).then((result) => {
          setBox(result)
          setFormBox(result)
        })
      } else {
        navigate('error')
      }
    }
    setLoading(false)
  }, [location, params, setAlert, navigate])

  useEffect(() => {
    getLines(formBox.plant).then((lines) => {
      setLineOptions(lines)
    })
  }, [formBox.plant])

  return (
    <Crud
      create={create}
      loading={loading}
      setLoading={(bool) => setLoading(bool)}
      name='box'
      baseUrl='/boxes'
      object={box}
      editObject={formBox}
      deleteFunc={(id) => deleteBox(id)}
      createFunc={(object) => addBox(object)}
      updateFunc={(object) => updateBox(object)}
      infoTableItems={['id', 'status', 'owner_email']}
      underTableItems={
        formBox.status === 'connected' &&
        formBox.ip && [
          <p>
            Click{' '}
            <a
              href={'http://' + formBox.ip + ':3000'}
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>{' '}
            to connect to the box, or scan this QR code:
          </p>,
          <QRCode value={'http://' + formBox.ip + ':3000'} />,
          <br />,
          <br />,
          <p>
            (you must be on the same network as the box in order for this to
            work)
          </p>
        ]
      }
      createOnlyItems={[
        <TextInput
          title='Enter part number displayed on the box:'
          label='Part Number:'
          value={formBox.id}
          setValue={(e) => setFormBox({ ...formBox, id: e.target.value })}
        />
      ]}
      formItems={[
        <Dropdown
          question='Select the appropriate Plant:'
          Label='Plant'
          creatable
          options={plantOptions}
          setFunc={(val) =>
            setFormBox({ ...formBox, line: 'other', plant: val })
          }
          value={formBox.plant}
        />,
        <Dropdown
          question='Select the appropriate Line:'
          label='Line'
          creatable
          options={lineOptions}
          setFunc={(val) => setFormBox({ ...formBox, line: val })}
          value={formBox.line}
        />,
        <TextInput
          title='Description:'
          label='Description'
          big={true}
          value={formBox.description}
          setValue={(e) =>
            setFormBox({ ...formBox, description: e.target.value })
          }
        />
      ]}
      checkComplete={() =>
        create ? !formBox.id : JSON.stringify(formBox) === JSON.stringify(box)
      }
    />
  )
}

export default Box
