import React, { useEffect } from 'react'
import '../styling/pages/ErrorPage.css'
import issue from '../assets/issue.svg'
import { useNavigate } from 'react-router-dom'

const ErrorPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/error')
  }, [navigate])

  return (
    <div className='error-page'>
      <img
        id='issue-image'
        alt='indication that something is wrong'
        src={issue}
      />
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  )
}

export default ErrorPage
