import axiosClient from '../config'

export const getBoxes = () => {
  return axiosClient.get('/boxes').then((result) => result.data)
}

export const getBox = (id) => {
  return axiosClient.get('/boxes/' + id).then((result) => result.data)
}

export const addBox = (box) => {
  return axiosClient.post('/boxes', {
    id: box.id,
    plant: box.plant,
    line: box.line,
    description: box.description || ''
  })
}

export const updateBox = (box) => {
  return axiosClient.patch('/boxes', {
    id: box.id,
    plant: box.plant,
    line: box.line,
    description: box.description || ''
  })
}

export const deleteBox = (id) => {
  return axiosClient.delete('/boxes/' + id)
}

export const getPlants = () => {
  return getBoxes().then((boxes) =>
    Array.from(new Set(['other', ...boxes.map((item) => item.plant)]))
  )
}

export const getLines = (plant) => {
  return getBoxes().then((boxes) =>
    Array.from(
      new Set([
        'other',
        ...boxes.filter((item) => item.plant === plant).map((item) => item.line)
      ])
    )
  )
}

export const getExamples = (id) => {
  return axiosClient
    .get('/boxes/' + id + '/examples')
    .then((result) => result.data)
}
